<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <BackButton :handler="backToReports" />
      </v-col>
    </v-row>

    <div class="app mt-4">
      <div class="user-wrapper">
        <div
          class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50"
        >
          <v-row>
            <v-col cols="12">
              <div class="" style="background-color: rgba(17, 42, 70, 0.04)">
                <p class="pa-4 font-extrabold text-xl text-blue">Filters</p>
              </div>
            </v-col>
          </v-row>

          <v-row class="">
            <v-col cols="2">
              <v-autocomplete
                v-model="searchParams.productTypeId"
                outlined
                placeholder="Product Type"
                item-value="id"
                item-text="name"
                return-object
                :items="productTypes"
                class="q-autocomplete shadow-0"
                hide-details
                dense
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="2">
              <v-autocomplete
                v-model="searchParams.venueServiceId"
                outlined
                placeholder="Services"
                item-value="id"
                item-text="name"
                return-object
                :items="venueServices"
                class="q-autocomplete shadow-0"
                hide-details
                dense
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="2">
              <v-select
                v-model="searchParams.sourceIds"
                outlined
                background-color="#fff"
                label="Select Source"
                item-value="id"
                item-text="name"
                return-object
                :items="sources"
                multiple
                class="q-autocomplete shadow-0"
                hide-details
                dense
              >
                <template
                  v-if="
                    searchParams.sourceIds != null &&
                    searchParams.sourceIds.length == sources.length
                  "
                  v-slot:selection="{ index }"
                >
                  <span v-if="index == 0" class="ellipsis-small"
                    >All Source</span
                  >
                </template>
                <template v-else v-slot:selection="{ item, index }">
                  <span v-if="index == 0">
                    {{ item.name }}
                  </span>
                  <span v-if="index == 1"> , ... </span>
                </template>

                <template v-slot:prepend-item>
                  <v-list-item
                    ripple
                    @click="toggle('sources', 'sourceIds', 1)"
                  >
                    <v-list-item-action>
                      <v-icon
                        :color="
                          searchParams.sourceIds != null &&
                          searchParams.sourceIds.length > 0
                            ? 'indigo darken-4'
                            : ''
                        "
                        >{{ getIcon("sources", "sourceIds", 1) }}</v-icon
                      >
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>All</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>

            <v-col cols="2" style="padding-right: 0">
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    outlined
                    v-model="date1Formatted"
                    readonly
                    v-on="on"
                    class="q-text-field shadow-0"
                    hide-details
                    dense
                    style="background-color: #ffffff"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="searchParams.from_date"
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="2">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="date2Formatted"
                    outlined
                    readonly
                    v-on="on"
                    class="q-text-field shadow-0"
                    hide-details
                    dense
                    style="background-color: #ffffff"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="searchParams.to_date"
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="2">
              <v-flex xs1 class="ml-1">
                <v-btn color="#00b0af" height="40" dark @click="getReport"
                  >Go</v-btn
                >
              </v-flex>
            </v-col>
          </v-row>

          <div class="md-card md-theme-default shadow-0 rounded-5">
            <div class="search_customer">
              <div class="header_title pl-4 pr-4">
                <v-row>
                  <v-col md="4">Product Sales Report</v-col>

                  <v-col md="4"> </v-col>

                  <v-col md="4" class="text-right">
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-if="
                            checkExportPermission($modules.reports.product.slug)
                          "
                          class="export-button mt-2"
                          elevation="0"
                          height="40"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <SvgIcon text="Export Report">
                            <template v-slot:icon>
                              <ExportIcon />
                            </template>
                          </SvgIcon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item @click="reportDownload('pdf')">
                          <v-list-item-icon>
                            <v-icon>mdi-file-pdf-box</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Export as PDF</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="reportDownload('excel')">
                          <v-list-item-icon>
                            <v-icon>mdi-file-excel-box</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Export as Excel</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>
                </v-row>
              </div>
            </div>

            <div class="md-card-content">
              <div>
                <div
                  class="md-content md-table md-theme-default table_borders"
                  table-header-color="orange"
                  value
                >
                  <div
                    v-resize="onResize"
                    :style="{ height: `${containerHeight}px` }"
                  >
                    <table class="table table-striped header-fixed">
                      <thead class="md-card-header sticky shadow-0">
                        <tr>
                          <th class="md-table-head sticky">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label">Timestamp</div>
                            </div>
                          </th>
                          <th class="md-table-head sticky">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label">
                                Product Type
                              </div>
                            </div>
                          </th>
                          <th class="md-table-head sticky">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label">Service</div>
                            </div>
                          </th>
                          <th class="md-table-head sticky">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label">
                                Product Name
                              </div>
                            </div>
                          </th>

                          <th class="md-table-head sticky">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label">Quantity</div>
                            </div>
                          </th>
                          <th class="md-table-head sticky">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label">Tax</div>
                            </div>
                          </th>
                          <th class="md-table-head sticky">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label">Price</div>
                            </div>
                          </th>
                        </tr>
                      </thead>

                      <tbody
                        v-if="productSalesReportData.length"
                        :set="
                          ((service_name = ''),
                          (product_type = ''),
                          (created_at = ''),
                          (total_quantity = 0),
                          (total_tax = 0),
                          (total_price = 0))
                        "
                      >
                        <template
                          v-for="(order, index) in productSalesReportData"
                        >
                          <tr
                            :key="`obj_day_total_${index}`"
                            class="md-table-row grand_total_bg font-weight-black"
                            v-if="
                              order.created_date != created_at && index != 0
                            "
                          >
                            <td
                              :colspan="3"
                              class="md-table-cell-container text-center"
                            >
                              <div
                                class="md-table-cell-container text-center font-weight-black"
                              >
                                Total
                              </div>
                            </td>
                            <td></td>
                            <td class="md-table-cell-container text-center">
                              {{ quantity | formatDecimalNumber }}
                            </td>
                            <td class="md-table-cell-container text-center">
                              {{ currencyCode }}
                              {{ tax ? tax.toFixed(2) : 0 }}
                            </td>
                            <td class="md-table-cell-container text-center">
                              {{ currencyCode }}
                              {{ price ? price.toFixed(2) : 0 }}
                            </td>
                          </tr>
                          <span
                            v-if="order.created_date != created_at"
                            :key="`obj_head_${index}`"
                            :set="
                              ((service_name = ''),
                              (product_type = ''),
                              (created_at = order.created_date),
                              (quantity = 0),
                              (tax = 0),
                              (price = 0))
                            "
                          >
                          </span>

                          <tr :key="`item_${index}`">
                            <td
                              :colspan="1"
                              class="md-table-cell-container text-center"
                            >
                              {{ created_at | reportDateformat }}
                            </td>
                            <td
                              :colspan="1"
                              class="md-table-cell-container text-center"
                              v-if="order.product_type != product_type"
                              :set="
                                ((product_type = order.product_type),
                                (service_name = ''))
                              "
                            >
                              <div
                                class="md-table-cell-container text-center font-weight-black"
                              >
                                <span data-v-b74a88c0="" class="orange--text">
                                  {{ order.product_type }}
                                </span>
                              </div>
                            </td>
                            <td v-else></td>
                            <td
                              :colspan="1"
                              class="md-table-cell-container text-center"
                              v-if="order.service_name != service_name"
                              :set="(service_name = order.service_name)"
                            >
                              <div
                                class="md-table-cell-container text-center font-weight-black"
                              >
                                <span data-v-b74a88c0="" class="orange--text">
                                  {{ order.service_name }}
                                </span>
                              </div>
                            </td>
                            <td v-else></td>
                            <td
                              :colspan="1"
                              class="md-table-cell-container text-center"
                            >
                              {{ order.product_name }}
                              <span
                                class="blue--text"
                                style="font-weight: 600"
                                v-if="order.category_name == 'open_product'"
                                >(Open product)</span
                              >
                            </td>

                            <td
                              :colspan="1"
                              class="md-table-cell-container text-center"
                              :set="
                                ((total_quantity += order.total_quantity),
                                (quantity += order.total_quantity))
                              "
                            >
                              {{ order.total_quantity | formatDecimalNumber }}
                            </td>
                            <td
                              :colspan="1"
                              class="md-table-cell-container text-center"
                              :set="
                                ((total_tax += order.total_tax),
                                (tax += order.total_tax))
                              "
                            >
                              {{ currencyCode }}
                              {{ order.total_tax.toFixed(2) }}
                            </td>
                            <td
                              :colspan="1"
                              class="md-table-cell-container text-center"
                              :set="
                                ((total_price += order.total_price),
                                (price += order.total_price))
                              "
                            >
                              {{ currencyCode }}
                              {{ order.total_price.toFixed(2) }}
                            </td>
                          </tr>
                        </template>
                        <tr
                          :key="`obj_day_last_row_total`"
                          class="md-table-row grand_total_bg font-weight-black"
                        >
                          <td
                            :colspan="3"
                            class="md-table-cell-container text-center"
                          >
                            <div
                              class="md-table-cell-container text-center font-weight-black"
                            >
                              Total
                            </div>
                          </td>
                          <td></td>
                          <td class="md-table-cell-container text-center">
                            {{ quantity | formatDecimalNumber }}
                          </td>
                          <td class="md-table-cell-container text-center">
                            {{ currencyCode }}
                            {{ tax.toFixed(2) }}
                          </td>
                          <td class="md-table-cell-container text-center">
                            {{ currencyCode }}
                            {{ price.toFixed(2) }}
                          </td>
                        </tr>
                        <tr :key="`obj_head_total`" class="head_bg">
                          <td
                            :colspan="columns.length"
                            :set="((service_name = ''), (product_type = ''))"
                          >
                            <div
                              class="md-table-cell-container text-center font-weight-black"
                            >
                              Bills for Date:
                              {{ searchParams.from_date | reportDateformat }}
                              to
                              {{ searchParams.to_date | reportDateformat }}
                            </div>
                          </td>
                        </tr>
                        <template
                          v-for="(
                            orderTotal, indexTotal
                          ) in productSalesReportTotal"
                        >
                          <tr :key="`item_total_${indexTotal}`">
                            <td></td>
                            <td
                              :colspan="1"
                              class="md-table-cell-container text-center"
                              v-if="orderTotal.product_type != product_type"
                              :set="
                                ((product_type = orderTotal.product_type),
                                (service_name = ''))
                              "
                            >
                              <div
                                class="md-table-cell-container text-center font-weight-black"
                              >
                                <span data-v-b74a88c0="" class="orange--text">
                                  {{ orderTotal.product_type }}
                                </span>
                              </div>
                            </td>
                            <td v-else></td>
                            <td
                              :colspan="1"
                              class="md-table-cell-container text-center"
                              v-if="orderTotal.service_name != service_name"
                              :set="(service_name = orderTotal.service_name)"
                            >
                              <div
                                class="md-table-cell-container text-center font-weight-black"
                              >
                                <span data-v-b74a88c0="" class="orange--text">
                                  {{ orderTotal.service_name }}
                                </span>
                              </div>
                            </td>
                            <td v-else></td>
                            <td
                              :colspan="1"
                              class="md-table-cell-container text-center"
                            >
                              {{ orderTotal.product_name }}
                              <span
                                class="blue--text"
                                style="font-weight: 600"
                                v-if="
                                  orderTotal.category_name == 'open_product'
                                "
                                >(Open product)</span
                              >
                            </td>

                            <td
                              :colspan="1"
                              class="md-table-cell-container text-center"
                            >
                              {{
                                orderTotal.total_quantity | formatDecimalNumber
                              }}
                            </td>
                            <td
                              :colspan="1"
                              class="md-table-cell-container text-center"
                            >
                              {{ currencyCode }}
                              {{
                                orderTotal.total_tax
                                  ? orderTotal.total_tax.toFixed(2)
                                  : 0
                              }}
                            </td>
                            <td
                              :colspan="1"
                              class="md-table-cell-container text-center"
                            >
                              {{ currencyCode }}
                              {{
                                orderTotal.total_price
                                  ? orderTotal.total_price.toFixed(2)
                                  : 0 | toCurrency
                              }}
                            </td>
                          </tr>
                        </template>
                        <tr
                          class="md-table-row grand_total_bg font-weight-black sticky_bottom"
                        >
                          <td
                            :colspan="3"
                            class="md-table-cell-container text-center"
                          >
                            <div
                              class="md-table-cell-container text-center font-weight-black"
                            >
                              GRAND TOTAL
                            </div>
                          </td>
                          <td></td>
                          <td class="md-table-cell-container text-center">
                            {{ total_quantity | formatDecimalNumber }}
                          </td>
                          <td class="md-table-cell-container text-center">
                            {{ currencyCode }}
                            {{ total_tax.toFixed(2) }}
                          </td>
                          <td class="md-table-cell-container text-center">
                            {{ currencyCode }}
                            {{ total_price.toFixed(2) }}
                          </td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                        <tr>
                          <td :colspan="columns.length">
                            <v-card-text class="pa-8 text-center">
                              <h3>No data found!</h3>
                            </v-card-text>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import BackButton from "@/components/Common/BackButton.vue";
import SvgIcon from "@/components/Image/SvgIcon.vue";
import ExportIcon from "@/assets/images/misc/export-icon.svg";

export default {
  components: { ExportIcon, SvgIcon, BackButton },
  data() {
    return {
      productSalesReportData: [],
      productSalesReportTotal: [],
      menu1: false,
      menu2: false,
      fileName: null,
      searchParams: {
        productTypeId: null,
        venueServiceId: null,
        sourceIds: null,
        from_date: moment().subtract(30, "days").format("YYYY-MM-DD"),
        to_date: moment().format("YYYY-MM-DD"),
      },
      sources: [
        { id: "QPortal", name: "QPortal" },
        { id: "B2C", name: "Website" },
        { id: "Kiosk", name: "Kiosk" },
        // {id: 'Partner',name: 'Krews Mobile'},
      ],
      product_categories: [],
      products: [],
      containerHeight: 400,
      columnData: [
        { name: "Service Name", value: "service" },
        { name: "Product", value: "product_name" },
        { name: "Price", value: "product_price" },
        { name: "Tax", value: "product_tax" },
        { name: "Total Quantity", value: "total_quantity" },
        { name: "Total Tax", value: "total_tax" },
        { name: "Total Price", value: "total_price" },
      ],
      columns: [
        "Timestamp",
        "service",
        "product_name",
        "date",
        "total_quantity",
        "total_tax",
        "total_price",
      ],
    };
  },
  computed: {
    ...mapGetters({
      sportsCategory: "getSportsService",
    }),
    date1Formatted() {
      return moment(this.searchParams.from_date, "YYYY-MM-DD").format(
        "Do MMM YYYY"
      );
    },
    date2Formatted() {
      return moment(this.searchParams.to_date, "YYYY-MM-DD").format(
        "Do MMM YYYY"
      );
    },

    venueServices() {
      return this.$store.getters.getVenueServices.data;
    },
    productTypes() {
      return this.$store.getters.getProductTypes.data;
    },
  },
  mounted() {
    this.onResize();
    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices");
    }
    if (this.$store.getters.getProductTypes.status == false) {
      this.$store.dispatch("loadProductTypes");
    }
  },
  methods: {
    getIcon(type, field, searchP) {
      let icon = "mdi-checkbox-blank-outline";
      if (searchP) {
        if (
          this.searchParams[field] != null &&
          this.searchParams[field].length == this[type].length
        ) {
          icon = "mdi-close-box";
        }
        if (
          this.searchParams[field] != null &&
          this.searchParams[field].length > 0 &&
          this.searchParams[field].length != this[type].length
        )
          icon = "mdi-minus-box";
      } else {
        if (this[field].length == this[type].length) {
          icon = "mdi-close-box";
        }
        if (this[field].length > 0 && this[field].length != this[type].length)
          icon = "mdi-minus-box";
      }
      return icon;
    },
    toggle(type, field, searchP) {
      this.$nextTick(() => {
        if (searchP) {
          if (
            this.searchParams[field] != null &&
            this.searchParams[field].length == this[type].length
          ) {
            this.searchParams[field] = [];
          } else {
            this.searchParams[field] = this[type].map((x) => x.id);
          }
        } else {
          if (this[field].length == this[type].length) {
            this[field] = [];
          } else {
            this.searchParams[field] = this[type];
          }
        }
        // this.searchData();
      });
      this.$forceUpdate();
    },
    getSum(index) {
      console.log(index);
    },
    checkTableColumnVisibility(column) {
      let index = this.columns.findIndex((item) => item == column);
      if (index == -1) {
        return false;
      }
      return true;
    },

    backToReports() {
      this.$router.push({
        name: "Reports",
      });
    },

    getFilterUrlData() {
      if (
        moment(this.searchParams.from_date, "YYYY-MM-DD").isAfter(
          moment(this.searchParams.to_date, "YYYY-MM-DD")
        )
      ) {
        this.showError("To date must be greater than or equal to from date");
        return;
      }
      let url = `?from_date=${this.searchParams.from_date}&to_date=${this.searchParams.to_date}`;
      this.fileName =
        this.searchParams.from_date + "-to-" + this.searchParams.to_date;
      let venueService = null;
      let productType = null;
      if (this.searchParams.venueServiceId) {
        url += `&venue_service_id=${this.searchParams.venueServiceId.venue_service_id}`;
        venueService = this.findValueByKey(
          this.venueServices,
          this.searchParams.venueServiceId.venue_service_id
        );
      }
      if (this.searchParams.sourceIds) {
        if (
          this.searchParams.sourceIds.length > 0 &&
          this.searchParams.sourceIds.length < this.sources.length
        ) {
          url += "&sources=" + this.searchParams.sourceIds.map((x) => x.id);
        }
      }

      if (this.searchParams.productTypeId) {
        url += `&product_type_id=${this.searchParams.productTypeId.id}`;
        productType = this.findValueByKey(
          this.productTypes,
          this.searchParams.productTypeId.product_type_id
        );
      }

      if (productType !== null)
        this.fileName += "-" + productType.name.replace(/\s/g, "");
      if (venueService !== null)
        this.fileName += "-" + venueService.name.replace(/\s/g, "");
      if (this.fileName != null)
        this.fileName = "Product-Sale-Report-" + this.fileName;
      else this.fileName = "Product-Sale-Report";
      return url;
    },
    findValueByKey(opts, key) {
      var match = opts.filter(function (opt) {
        return opt.id === key;
      });
      return match[0] ? match[0] : null;
    },
    getReport() {
      let url = this.getFilterUrlData();
      if (!url) return;
      this.showLoader("Loading");
      this.$http
        .get(`venues/reports/product-sale-report${url}`)
        .then((response) => {
          if (response.status == 200) {
            const data = response.data.data;
            this.productSalesReportData = [];
            this.productSalesReportTotal = [];
            data["dataArray"].forEach((log) => {
              // if (log.total_quantity == 0) {
              //   log.splice(index, 1);
              // }

              this.productSalesReportData.push(log);
            });
            data["totalArray"].forEach((log) => {
              // if (log.total_quantity == 0) {
              //   log.splice(index, 1);
              // }

              this.productSalesReportTotal.push(log);
            });
            //this.productSalesReport = data;
            this.hideLoader();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    reportDownload(type) {
      let link = "";
      if (type == "pdf") {
        link = "product-sale-report-pdf";
      } else if (type == "excel") {
        link = "product-sale-report";
      }
      let url = this.getFilterUrlData();
      if (!url) return;
      this.showLoader("Downloading report");
      this.$http
        .get(`venues/reports/` + link + `/download${url}&type=` + type, {
          responseType: "blob",
        })
        .then((response) => {
          this.hideLoader();
          if (response.status == 200) {
            this.downloadReport(response, this.fileName, type);
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    onResize() {
      this.containerHeight = window.innerHeight - 380;
    },
  },
};
</script>

<style scoped>
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #edf7f8;
  z-index: 1;
  padding: 10px 4px;
}
.md-table table {
  overflow: scroll;
}
.md-card .md-card-header {
  z-index: 1000;
}
</style>
